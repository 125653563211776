<template>
    <div v-if="isShowNav">
        <!--        <div class="top" :style="{'background': 'linear-gradient(180deg, rgba(0,0,0,0.4) 0%, rgba(0,0,0,0) 100%)', 'opacity': opacity}">-->
        <div class="top" :style="{'background': isScroll&&!isShow?'#FFFFFF':'linear-gradient(180deg, rgba(0,0,0,0.4) 0%, rgba(0,0,0,0) 100%)', 'opacity': 1,
             'borderBottom':isScroll&&!isShow?'1px solid rgba(0,0,0,0.1)':''}">
            <div class="top-logo" @click="onLogoClick">
                <img class="logo" src="../img/logo.png" alt="">
            </div>
            <div class="top-content">
				<span>
					<span v-for="(item,index) in topTitle" :class="tabIndex==item.url?'border':''" :key="index"
                          class="title" @click="skip(item.url)"
                          :style="{'color':isScroll?'#333333':'#FFFFFF'}">{{ item.title }}</span>
				</span>
            </div>

            <div class="menu-box">
                <i class="el-icon-s-operation icon" @click="menuShow= !menuShow"></i>
                <!--                <ul class="menu-list" v-show="menuShow">-->
                <!--                    <li v-for="(item,index) in topTitle" :key="index" class="title"-->
                <!--                        :class="tabIndex==item.url?'border':''" @click="skip(item.url,index);menuShow= false">-->
                <!--                        {{ item.title }}-->
                <!--                    </li>-->
                <!--                </ul>-->
                <el-drawer
                    :size="150"
                    title=""
                    :show-close="false"
                    :visible.sync="menuShow"
                    :direction="direction"
                    :before-close="handleClose">
                    <div class="logoLayout">
                        <img class="dwLogo" src="../img/logo.png" alt="">
                    </div>
                    <div class="tabItem" v-for="(item,index) in tabsPhone" :key="index" @click="skip(item.url,index)">
                        {{ item.title }}
                    </div>
                </el-drawer>
            </div>
        </div>

    </div>
</template>

<script>
export default {
    data() {
        return {
            direction: 'rtl',
            isShow: false,
            isScroll: false,
            opacity: 1,
            menuShow: false,
            tabIndex: '/',
            backColor: false,
            topTitle: [
                {
                    title: "首页",
                    url: "/"
                },
                {
                    title: "关于我们",
                    url: "/about"
                },
                {
                    title: "代理加盟",
                    url: "/knowTakeout"
                },
                {
                    title: "商家入驻",
                    url: "/openAShop"
                },
                // {
                // title: "投资者关系",
                // url: "/investment"
                // },
                {
                    title: "子贡研究院",
                    url: "/zigong"
                },
                {
                    title: "社会责任",
                    url: "/public"
                },
                {
                    title: "最新资讯",
                    url: "/news"
                },
                {
                    title: "下载APP",
                    url: "/orderingFoodDelivery"
                },
                {
                    title: "加入我们",
                    url: "/joinUs"
                },
                // {
                // title: "开放平台",
                // url: "/myop"
                // },
            ],
            tabsPhone: [
                {
                    title: "首页",
                    url: "/"
                },
                {
                    title: "关于我们",
                    url: "/about"
                },
                {
                    title: "代理加盟",
                    url: "/knowTakeout"
                },
                {
                    title: "商家入驻",
                    url: "/openAShop"
                },
                {
                    title: "下载APP",
                    url: "/orderingFoodDelivery"
                },
            ],
            isShowNav: true,
            isChangeTitleBar: true,
            homeScrollTop: 0,
        }
    },
    watch: {
        // 监听 $route 对象的变化
        $route(to, from) {
            // 处理导航地址栏变化的逻辑
            this.tabIndex = to.path
            console.log(from)
        }
    },
    created() {
        // 判断当前环境是手机还是电脑
        let device = navigator.userAgent;
        // 判断是手机还是电脑
        this.isShow = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(device);
        console.log("this.isShow = " + this.isShow)
    },
    mounted() {
        // 监听页面滚动事件
        window.addEventListener("scroll", this.scrolling);
        // // 获取需要监听滚动事件的元素
        // var element = document.querySelector('.scroll_page');
        // // 添加滚动事件监听器 event.target.scrollTop滚动条距离顶部的高度
        // element.addEventListener('scroll', this.homeScroll);
    },
    methods: {
        onLogoClick() {
            this.$router.push({
                path: "/"
            })
        },
        handleClose(done) {
            done();
        },
        // 首页使用overflow-y: scroll; 的监听事件
        homeScroll(e) {
            if (e.target.scrollTop == 0) {
                this.isScroll = false
            } else if (e.target.scrollTop > 50) {
                this.isScroll = true
            }
        },
        skip(url) {
            console.log("url = "+url)
            this.$router.replace(url)
            this.isChangeTitleBar = url == '/'
            this.isScroll = !this.isChangeTitleBar
            if (this.isShow) {
                this.menuShow = false
            }
            // 给app.vue传参
            this.$emit('clickMenu')
        },

        scrolling() {
            if (this.isChangeTitleBar) {
                // if (window.scrollY == 0) {
                // this.isScroll = false
                // } else if (window.scrollY > 50) {
                // this.isScroll = true
                // }
            } else {
                this.isScroll = true
            }

            // 滚动条距文档顶部的距离
            let scrollTop =
                window.pageYOffset ||
                document.documentElement.scrollTop ||
                document.body.scrollTop;
            // 滚动条滚动的距离
            let scrollStep = scrollTop - this.oldScrollTop;
            // console.log("header 滚动距离 ", scrollTop);
            // 更新——滚动前，滚动条距文档顶部的距离
            this.oldScrollTop = scrollTop;

            //变量windowHeight是可视区的高度
            let windowHeight =
                document.documentElement.clientHeight || document.body.clientHeight;
            //变量scrollHeight是滚动条的总高度
            let scrollHeight =
                document.documentElement.scrollHeight || document.body.scrollHeight;

            //滚动条到底部的条件
            if (scrollTop + windowHeight == scrollHeight) {
                //你想做的事情
                // console.log("header 你已经到底部了");
            }
            if (scrollStep < 0) {
                // console.log("header 滚动条向上滚动了！");
            } else {
                // console.log("header 滚动条向下滚动了！");
                this.backColor = true
            }
            // 判断是否到了最顶部
            if (scrollTop <= 0) {
                this.backColor = false
                // console.log("header 到了最顶部")
            }
        },
    },
}
</script>

<style scoped lang="less">
@import url("../css/navTop.less");
</style>