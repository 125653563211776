import axios from 'axios'

const instance = axios.create({
	baseURL: 'https://admin.waimaishi.cn/prod-api/admin-api/', // 接口的基础url
	// baseURL: 'http://192.168.1.37:48088/admin-api/', // 接口的基础url
	timeout: 5000, // 请求超时时间
	headers: {
		'Content-Type': 'application/json'
	}
})

// 请求拦截器
instance.interceptors.request.use(
	config => {
		// 在请求发送之前做些什么
		// 可以添加token等认证信息
		return config
	},
	error => {
		// 对请求错误做些什么
		console.log(error)
		return Promise.reject(error)
	}
)

// 响应拦截器
instance.interceptors.response.use(
	response => {
		// 对响应数据做些什么
		return response.data
	},
	error => {
		// 对响应错误做些什么
		console.log(error)
		return Promise.reject(error)
	}
)

// 封装get请求
function get(url, params) {
	return instance({
		url: url,
		method: 'get',
		params: params
	})
}

// 封装post请求
function post(url, data) {
	return instance({
		url: url,
		method: 'post',
		data: data
	})
}

export default {
	get,
	post
}