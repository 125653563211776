import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [{
    path: '/',
    name: 'home',
    component: HomeView,
    meta: {
        title: "外卖狮"
    }
},
    {
        path: '/about',
        name: 'about',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import( /* webpackChunkName: "about" */ '../views/AboutView.vue'),
        meta: {
            title: "外卖狮-入驻代理"
        }
    },
    {
        path: '/knowtakeout',
        name: 'knowtakeout',
        component: () => import('../views/knowTakeout.vue'),
        meta: {
            title: "了解外卖狮"
        }
    },
    {
        path: '/public',
        name: 'public',
        component: () => import('../views/publicWelfare.vue'),
        meta: {
            title: "大爱天下公益事业部"
        }
    },
    {
        path: '/investment',
        name: 'investment',
        component: () => import('../views/investmentRelationship.vue'),
        meta: {
            title: "投资者关系"
        }
    },
    {
        path: '/myop',
        name: 'myop',
        component: () => import('../views/myopView.vue'),
        meta: {
            title: "开放平台"
        }
    },
    {
        path: '/news',
        name: 'news',
        component: () => import('../views/newsView.vue'),
        meta: {
            title: "新闻资讯"
        }
    },
    {
        path: '/zigong',
        name: 'zigong',
        component: () => import('../views/zigongCollege.vue'),
        meta: {
            title: "子贡乡村数字研究院"
        }
    },
    {
        path: '/join',
        name: 'join',
        component: () => import('../views/joinUs.vue'),
        meta: {
            title: "加入我们"
        }
    },
    {
        path: '/openAShop',
        name: 'openAShop',
        component: () => import('../views/openAShop.vue'),
        meta: {
            title: "我要开店"
        }
    },
    {
        path: '/orderingFoodDelivery',
        name: 'orderingFoodDelivery',
        component: () => import('../views/orderingFoodDelivery.vue'),
        meta: {
            title: "我要点外卖"
        }
    },
    {
        path: '/joinUs',
        name: 'joinUs',
        component: () => import('../views/joinUs.vue'),
        meta: {
            title: "加入我们"
        }
    },
    {
        path: '/PrintSetting',
        name: 'PrintSetting',
        component: () => import('../views/PrintSetting.vue'),
        meta: {
            title: "打印机设置"
        }
    }
]

const router = new VueRouter({
    mode: "hash",
    base: process.env.BASE_URL,
    routes
})

//然后就是在 路由守卫的时候 这样写..
router.beforeEach((to, from, next) => {
    window.scrollTo(0, 0);
    document.title = to.meta.title; // 重要
    next();
})
export default router