<template>
    <div>
        <div class="scroll_page" v-if="!isShow">
            <div class="height_100">
                <div class="home">
                    <video :controls="false" ref="videoRef"
                           src="https://wms-1306213729.cos.ap-beijing.myqcloud.com/website/bgmp4.mp4"
                           style="object-fit:cover" poster='../img/home/homemp4.jpg' autoplay autoplay-loop muted
                           width="100%" height="100%">
                    </video>
                    <!--            <div class="join">-->
                    <!--                <div class="join-item" @click="jump(item.src)" v-for="item in homeJoin" :key="item.title">-->
                    <!--                    <h4>{{ item.title }} <i class="el-icon-arrow-right"></i></h4>-->
                    <!--                    <p>{{ item.content }}</p>-->
                    <!--                </div>-->
                    <!--            </div>-->
                    <div class="arrowLayout">
                        <div class="arrowContainer">
                            <div class="chevron"></div>
                            <div class="chevron"></div>
                            <div class="chevron"></div>
                            <div class="text">更多内容</div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="height_100">
                <div class="missionContent">
                    <div id="target2">
                        <Transition name="bounce">
                            <div class="title target2Content" v-show="isAnim2">
                                <h2 class="mission">使
                                    <img class="mission_star_icon" src="../img/icons/star_icon.png" alt="">
                                    命
                                </h2>
                                <p class="mission_content">为全国数字乡村<br/> 最后一公里的便捷服务持续奋斗</p>

                                <div class="dLine"></div>

                                <button class="learn-more" @click="onAboutUsClick">
									<span class="circle" aria-hidden="true">
										<span class="icon arrow"></span>
									</span>
                                    <span class="button-text">关于我们</span>
                                </button>
                            </div>
                        </Transition>
                    </div>
                </div>
            </div>

            <div class="height_100">
                <div class="retail" style="margin-top: 0;padding-top: 100px;box-sizing: border-box;">
                    <div id="target" class="targetContent">
                        <Transition name="bounce">
                            <div class="title titleContent" style="margin: 0 auto;" v-show="isAnim">
                                <div class="subTitleLayout">
                                    <div class="subTitleEn">BUSINESS</div>
                                    <div class="subTitleCn">业务板块</div>
                                </div>
                                <div class="subTitleDesc">在智慧零售前沿探索</div>
                            </div>
                        </Transition>
                    </div>

                    <div class="slider-box">
                        <div class="slider__nav">
                            <div class="item_all_title">品类全覆盖，应有尽有</div>
                            <div v-for="(image,index) in service.images" :key="index" class="slider__nav-item"
                                 :class="{ 'is-active': service.currentIndex === index }"
                                 @click="serviceHandleClickNav(index)" @mouseover="serviceHandleMouseOver(index)"
                                 @mouseleave="serviceHandleMouseLeave()">
                                <!-- <img :src="require(`../img/home/${service.images[0].icon}`)"> -->
                                <div class="slider__nav-item_title">
                                    <img class="slider__nav-item_icon" style="width: 10rpx;height: 10rpx;"
                                         :src="require(`../img/home/${service.currentIndex==index?service.images[index].icon2:service.images[index].icon}`)">
                                    {{ image.title }}
                                    {{ image.orderTitle }}
                                </div>
                                <div :class="service.currentIndex === index?'slider__bottom_show':''"
                                     class="slider__bottom">
                                </div>
                            </div>
                        </div>
                        <div class="slider">
                            <div class="slider__wrapper" v-if="service.images.length > 0">
                                <transition-group name="fade">
                                    <img :src="require(`../img/home/${service.images[service.currentIndex].src}`)"
                                         :key="1">
                                </transition-group>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="height_100" style="padding-top: 100px;box-sizing: border-box;">
                <div id="target3">
                    <Transition name="homeBtm">
                        <div class="love-box target3Content" v-show="isAnim3">
                            <div class="love-box-left" @click="jump('/zigong')">
                                <div class="love-mask love-mask-left">
                                    <h3 class="mask-title">子贡乡村数字研究院</h3>
                                    <div class="mask-title-border"></div>
                                    <div class="mask-content">
                                        <p style="text-align: center;margin: 5px;font-weight: bold;">价值观</p>
                                        <p style="text-align: center;">创新、可靠、合作、感恩</p>
                                        <p style="text-align: center;margin: 5px;font-weight: bold;">愿景</p>
                                        <p>成为全球数字乡村领域的领先机构，为农村地区提供创新的数字技术解决方案，
                                            推动农村地区的现代化和可持续发展。</p>
                                    </div>
                                </div>
                            </div>
                            <div class="love-box-reight">
                                <div class="love-item" @click="jump('/join')">
                                    <img src="../img/home/service_knight.png" alt="">
                                    <div class="love-mask love-mask-reight">
                                        <h3 class="mask-title">几十万 外卖狮骑士</h3>
                                        <div class="mask-title-border"></div>
                                        <div class="mask-content">
                                            几十万众包骑士构建超强网络运力
                                        </div>
                                    </div>
                                </div>
                                <div class="love-item">
                                    <img src="../img/home/service_person.jpg" alt="">
                                    <div class="love-mask love-mask-reight">
                                        <h3 class="mask-title">领跑乡镇外卖平台！</h3>
                                        <div class="mask-title-border"></div>
                                        <div class="mask-content">
                                            让你在家享受美食，不再添堵
                                            让新农村生活更加便利！
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="love-box-reight">
                                <div class="love-item">
                                    <img src="../img/home/service_city.png" alt="">
                                    <div class="love-mask love-mask-reight">
                                        <h3 class="mask-title">全国县区市覆盖</h3>
                                        <div class="mask-title-border"></div>
                                        <div class="mask-content">
                                            为全国县区市的商家提供安全优质的全渠道履约解决方案和本地配送服务
                                        </div>
                                    </div>
                                </div>
                                <div class="love-item" @click="jump('/public')">
                                    <img src="../img/home/service_daily.png" alt="">
                                    <div class="love-mask love-mask-reight">
                                        <h3 class="mask-title">大爱天下公益事业部</h3>
                                        <div class="mask-title-border"></div>
                                        <div class="mask-content" style="font-size: 16px;">
                                            <p><span style="font-weight: bold;">价值观：</span>大爱、信任、协作、奉献</p>
                                            <span
                                                style="font-weight: bold;">愿景：</span>致力于推进全面、可持续的公益事业，为倡导全社会关注、参与公益事业，贡献自己的力量，实现公益梦想，为人类文明之进步、和谐之美、科技之益，做出自己的贡献
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Transition>
                </div>

                <div class="cooperate">
                    <h3 class="cooperate-title">常见问题</h3>
                    <div class="cooperate-content" style="color: #999999;cursor: pointer;margin: 10px 0 10px;"
                         @click="onPrintSettingClick">
                        打印机设置
                    </div>
                    <h3 class="cooperate-title">友情链接</h3>
                    <div class="cooperate-content">
                        <div class="cooperate-content-box">
                            <a class="cooperate-content-item" href="https://www.nrra.gov.cn/"
                               target="_blank">国家乡村振兴局</a>
                            <a class="cooperate-content-item" href="https://www.mct.gov.cn/"
                               target="_blank">中华人民共和国文化和旅游部</a>
                            <a class="cooperate-content-item" href="http://www.moa.gov.cn/"
                               target="_blank">中华人民共和国农业农村部</a>
                            <a class="cooperate-content-item" href="https://www.most.gov.cn/index.html"
                               target="_blank">中华人民共和国科技技术部</a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="height_100" style="height: 340px;">
                <navFoot></navFoot>
            </div>
        </div>
        <div v-else>
            <!--            <div class="scroll_page">-->
            <!--            </div>-->
            <!--            <div class="height_100">-->
            <div class="home_tel">
                <video :controls="true" ref="videoRef" id="video"
                       src="https://wms-1306213729.cos.ap-beijing.myqcloud.com/website/bgmp4.mp4"
                       style="object-fit:cover" poster='../img/home/homemp4.jpg'
                       muted
                       :autoplay="true"
                       :playsinline="true"
                       :webkit-playsinline="true"
                       x5-video-player-type="h5-page"
                       x5-video-orientation="landscape|portrait"
                       width="100%" height="100%">
                </video>
                <!--                autoplay-->
                <!--                autoplay-loop-->
                <!--                <div class="arrowLayout">-->
                <!--                    <div class="arrowContainer">-->
                <!--                        <div class="chevron"></div>-->
                <!--                        <div class="chevron"></div>-->
                <!--                        <div class="chevron"></div>-->
                <!--                        <div class="text">更多内容</div>-->
                <!--                    </div>-->
                <!--                </div>-->
            </div>
            <!--            </div>-->

            <!--            <div class="height_100">-->
            <div class="missionContent_tel">
                <div id="target2">
                    <Transition name="bounce">
                        <div class="target2Content_tel" v-show="isAnim2">
                            <h2 class="mission_tel">使
                                <img class="mission_star_icon_tel" src="../img/icons/star_icon.png" alt="">
                                命
                            </h2>
                            <p class="mission_content_tel">为全国数字乡村<br/> 最后一公里的便捷服务持续奋斗</p>
                            <div class="dLine"></div>

                            <button class="learn-more" @click="onAboutUsClick">
									<span class="circle" aria-hidden="true">
										<span class="icon arrow"></span>
									</span>
                                <span class="button-text">关于我们</span>
                            </button>
                        </div>
                    </Transition>
                </div>
            </div>
            <!--            </div>-->

            <!--            <div class="height_100">-->
            <div class="retail" style="margin-top: 0;box-sizing: border-box;">
                <div id="target" class="targetContent">
                    <Transition name="bounce">
                        <div class="titleContent_tel" v-show="isAnim">
                            <div class="subTitleLayout_tel">
                                <div class="subTitleEn_tel">BUSINESS</div>
                                <div class="subTitleCn_tel">业务板块</div>
                            </div>
                            <div class="subTitleDesc_tel">在智慧零售前沿探索</div>
                        </div>
                    </Transition>
                </div>

                <div class="slider-box_tel">
                    <div class="slider__nav_tel">
                        <div class="item_all_title_tel">品类全覆盖，应有尽有</div>
                        <div v-for="(image,index) in service.images" :key="index" class="slider__nav-item_tel"
                             :class="{ 'is-active': service.currentIndex === index }"
                             @click="serviceHandleClickNav(index)" @mouseover="serviceHandleMouseOver(index)"
                             @mouseleave="serviceHandleMouseLeave()">
                            <!-- <img :src="require(`../img/home/${service.images[0].icon}`)"> -->
                            <div class="slider__nav-item_title_tel">
                                <img class="slider__nav-item_icon_tel" style="width: 10rpx;height: 10rpx;"
                                     :src="require(`../img/home/${service.currentIndex==index?service.images[index].icon2:service.images[index].icon}`)">
                                {{ image.title }}
                                {{ image.orderTitle }}
                            </div>
                            <div :class="service.currentIndex === index?'slider__bottom_show_tel':''"
                                 class="slider__bottom_tel">
                            </div>
                        </div>
                    </div>
                    <div class="slider__wrapper_tel" v-if="service.images.length > 0">
                        <transition-group name="fade">
                            <img :src="require(`../img/home/${service.images[service.currentIndex].src}`)"
                                 :key="1">
                        </transition-group>
                    </div>
                </div>
            </div>
            <!--            </div>-->
            <div style="box-sizing: border-box;">
                <div id="target3">
                    <!--                    <Transition name="homeBtm">-->
                    <!--                        <div class="love-box_tel" v-show="isAnim3">-->
                    <div class="love-box_tel">
                        <div class="love-box-left_tel" @click="!isShow?jump('/zigong'):''">
                            <img src="../img/home/service_merchant.png" alt="">
                            <div class="love-mask_tel love-mask-left_tel">
                                <h3 class="mask-title_tel">子贡乡村数字研究院</h3>
                                <div class="mask-title-border_tel"></div>
                                <div class="mask-content_tel">
                                    <p style="text-align: center;margin: 5px;font-weight: bold;">价值观</p>
                                    <p style="text-align: center;">创新、可靠、合作、感恩</p>
                                    <p style="text-align: center;margin: 5px;font-weight: bold;">愿景</p>
                                    <p>成为全球数字乡村领域的领先机构，为农村地区提供创新的数字技术解决方案，
                                        推动农村地区的现代化和可持续发展。</p>
                                </div>
                            </div>
                        </div>
                        <div class="love-box-reight_tel">
                            <div class="love-item_tel" @click="!isShow?jump('/join'):''">
                                <img src="../img/home/service_knight.png" alt="">
                                <div class="love-mask_tel love-mask-reight_tel">
                                    <h3 class="mask-title_tel">几十万 外卖狮骑士</h3>
                                    <div class="mask-title-border_tel"></div>
                                    <div class="mask-content_tel">
                                        几十万众包骑士构建超强网络运力
                                    </div>
                                </div>
                            </div>
                            <div class="love-item_tel">
                                <img src="../img/home/service_person.jpg" alt="">
                                <div class="love-mask_tel love-mask-reight_tel">
                                    <h3 class="mask-title_tel">领跑乡镇外卖平台！</h3>
                                    <div class="mask-title-border_tel"></div>
                                    <div class="mask-content_tel">
                                        让你在家享受美食，不再添堵
                                        让新农村生活更加便利！
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="love-box-reight_tel">
                            <div class="love-item_tel">
                                <img src="../img/home/service_city.png" alt="">
                                <div class="love-mask_tel love-mask-reight_tel">
                                    <h3 class="mask-title_tel">全国县区市覆盖</h3>
                                    <div class="mask-title-border_tel"></div>
                                    <div class="mask-content_tel">
                                        为全国县区市的商家提供安全优质的全渠道履约解决方案和本地配送服务
                                    </div>
                                </div>
                            </div>
                            <div class="love-item_tel" @click="!isShow?jump('/public'):''">
                                <img src="../img/home/service_daily.png" alt="">
                                <div class="love-mask_tel love-mask-reight_tel">
                                    <h3 class="mask-title_tel">大爱天下公益事业部</h3>
                                    <div class="mask-title-border_tel"></div>
                                    <div class="mask-content_tel" style="font-size: 15px;">
                                        <p><span style="font-weight: bold;">价值观：</span>大爱、信任、协作、奉献</p>
                                        <span
                                            style="font-weight: bold;">愿景：</span>致力于推进全面、可持续的公益事业，为倡导全社会关注、参与公益事业，贡献自己的力量，实现公益梦想，为人类文明之进步、和谐之美、科技之益，做出自己的贡献
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!--                    </Transition>-->
                </div>

                <div class="cooperate_tel" style="padding: 30px 30px 0;">
                    <h3 class="cooperate_tel-title_tel">常见问题</h3>
                    <div class="cooperate_tel-content_tel" style="color: #999999;cursor: pointer;"
                         @click="onPrintSettingClick">
                        打印机设置
                    </div>
                </div>

                <div class="cooperate_tel">
                    <h3 class="cooperate_tel-title_tel">友情链接</h3>
                    <div class="cooperate_tel-content_tel">
                        <div class="cooperate_tel-content-box_tel">
                            <a class="cooperate-content-item_tel" href="https://www.nrra.gov.cn/"
                               target="_blank">国家乡村振兴局</a>
                            <a class="cooperate-content-item_tel" href="https://www.mct.gov.cn/"
                               target="_blank">中华人民共和国文化和旅游部</a>
                            <a class="cooperate-content-item_tel" href="http://www.moa.gov.cn/"
                               target="_blank">中华人民共和国农业农村部</a>
                            <a class="cooperate-content-item_tel" href="https://www.most.gov.cn/index.html"
                               target="_blank">中华人民共和国科技技术部</a>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>
<script>
import navFoot from '../components/navFootToo.vue'

export default {
    components: {
        navFoot
    },
    data() {
        return {
            isShow: false,//判断是否是手机环境
            currentSectionIndex: 0,
            homeJoin: [{
                title: "我要代理",
                content: "商家、配送、城市代理、代理商",
                src: "/knowTakeout"
            }, {
                title: "我要开店",
                content: "新途径、新平台、轻松省心当老板 ",
                src: "/openAShop"
            }, {
                title: "我要点外卖",
                content: "便民外卖，急速跑腿、一键享受惬意生活",
                src: "/orderingFoodDelivery"
            }],
            // 业务板块
            service: {
                images: [{
                    src: "banner1.png",
                    title: "美食",
                    orderTitle: "海量菜系 随点随送",
                    icon: "icon1.png",
                    icon2: "icon1-h.png"
                },

                    {
                        src: "banner3.png",
                        title: "水果生鲜",
                        orderTitle: "新鲜营养 健康常驻",
                        icon: "icon3.png",
                        icon2: "icon3-h.png"
                    },
                    // {
                    // 	src: "banner4.png",
                    // 	title: "超市便利",
                    // 	orderTitle: "天天大促 超值优惠",
                    // 	icon: "icon4.png",
                    // 	icon2: "icon4-h.png"
                    // },
                    {
                        src: "banner4.png",
                        title: "甜点饮品",
                        orderTitle: "甜蜜精美 收获幸福",
                        icon: "icon2.png",
                        icon2: "icon2-h.png"
                    },

                    {
                        src: "banner2.png",
                        title: "快递跑腿",
                        orderTitle: "急速快捷 随用随取",
                        icon: "icon5.png",
                        icon2: "icon5-h.png"
                    },

                    // {
                    // 	src: "banner6.jpg",
                    // 	title: "代购代办",
                    // 	orderTitle: "省时省力 安心放心",
                    // 	icon: "icon6.png",
                    // 	icon2: "icon6-h.png"
                    // },
                ],
                currentIndex: 0,
                timer: null,
                isPaused: false,
            },
            isAnim: false,
            isAnim2: false,
            isAnim3: false,
            isIOS: false,
        }
    },
    created() {
        this.checkPlatform()
        // 判断当前环境是手机还是电脑
        let device = navigator.userAgent;
        // 判断是手机还是电脑
        this.isShow = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(device);
        console.log("this.isShow = " + this.isShow)
        if (this.isShow && !this.isIOS) {
            document.addEventListener('touchstart', function () {
                let video = document.getElementById('video');
                if (video) {
                    video.play()
                }
            })
        }
    },
    mounted() {
        this.service.timer = setInterval(() => {
            if (!this.service.isPaused) {
                this.serviceNextImage();
            }
        }, 3000);
        this.isViewVisible()

        if (!this.isShow) {
            // 当视频播放结束时，设置一个定时器自动重新播放
            this.$refs.videoRef.play();
        }
    },
    destroyed() {
        clearInterval(this.service.timer);
    },
    methods: {
        onPrintSettingClick() {
            this.$router.push({
                path: '/PrintSetting'
            })
        },
        checkPlatform() {
            const u = navigator.userAgent;
            this.isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
        },
        onAboutUsClick() {
            this.$router.push({
                path: '/about'
            })
        },
        isViewVisible() {
            const observer = new IntersectionObserver(entries => {
                entries.forEach(entry => {
                    if (entry.isIntersecting) {
                        this.isAnim = true
                    } else {
                        this.isAnim = false
                    }
                });
            });
            let target = document.querySelector("#target");
            observer.observe(target);
            const observer2 = new IntersectionObserver(entries => {
                entries.forEach(entry => {
                    if (entry.isIntersecting) {
                        this.isAnim2 = true
                    } else {
                        this.isAnim2 = false
                    }
                });
            });
            let target2 = document.querySelector("#target2");
            observer2.observe(target2);
            const observer3 = new IntersectionObserver(entries => {
                entries.forEach(entry => {
                    if (entry.isIntersecting) {
                        this.isAnim3 = true
                    } else {
                        this.isAnim3 = false
                    }
                });
            });
            let target3 = document.querySelector("#target3");
            observer3.observe(target3);
        },
        serviceNextImage() {
            this.service.currentIndex = (this.service.currentIndex + 1) % this.service.images.length;
        },
        serviceHandleClickNav(index) {
            this.service.currentIndex = index;
            this.service.isPaused = true;
        },
        serviceHandleMouseOver(index) {
            this.service.currentIndex = index;
            this.service.isPaused = true;
        },
        serviceHandleMouseLeave() {
            this.service.isPaused = false;
        },
        jump(url) {
            this.$router.push({
                path: url
            })
        },
    }
    ,
}
</script>
<style scoped lang="less">
@import url('../css/home.less');
</style>