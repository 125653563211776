<template>
    <div id="app">
        <nav>
            <navTop @clickMenu="clickMenu"></navTop>
        </nav>
        <router-view/>
        <goTop></goTop>
        <navFoot v-if="footShow||isShow"></navFoot>
    </div>
</template>
<script>
import navTop from '../src/components/navTop.vue'
import navFoot from '../src/components/navFootToo.vue'
import goTop from '../src/components/goTop.vue'

export default {
    components: {
        navTop,
        navFoot,
        goTop
    },
    mounted() {
        // 判断当前环境是手机还是电脑
        let device = navigator.userAgent;
        // 判断是手机还是电脑
        this.isShow = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(device);
    },
    watch: {
        $route(to, from) {
            this.footShow = !(to.path == '/')
            console.log('路由发生变化 to = ', to.path, from);
        }
    },
    data() {
        return {
            isShow: false,
            footShow: false
        }
    },
    methods: {
        clickMenu() {

        }
    },
}
</script>

<style lang="less">
@import url("css/base.less");
</style>