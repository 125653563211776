<template>
    <div class="page">
        <div :class="isShow?'left_message_tel':'left_message'">
            <div>
                <div class="message_add_join " v-if="messageAddShow" :class="goTopShow?'box_shadow':''">
                    <img class="message_add_join_bg_img" src="../img/zigong/mission5.png" alt="">
                    <div class="message_add_join_back"></div>
                    <div class="message_add_join_top">
                        <div>
                            请您留言
                        </div>
                        <div class="message_add_join_show_box" @click="messageAddShow = false">
                            <div class="message_add_join_show"></div>
                        </div>
                    </div>
                    <div class="message_add_form_title">
                        <div class="message_add_form_title_name">外卖狮代理商火热招募中</div>
                        <div class="message_add_form_title_name_too">代理咨询热线：400-666-9951</div>
                    </div>
                    <div class="form" style="width: 280px;margin: 0 auto;">
                        <div class="form_item">
                            <span class="form_item_title">姓名：</span>
                            <el-input placeholder="请输入您的姓名" v-model="user.name" autocomplete="off"></el-input>
                        </div>
                        <div class="form_item">
                            <span class="form_item_title">手机号：</span>
                            <el-input v-model="user.mobile" placeholder="请输入您的手机号"
                                      autocomplete="off"></el-input>
                        </div>
                        <div class="form_item_ads">
                            <span class="form_item_ads_title" style="width: 95px;">代理区域：</span>
                            <div class="form_item_ads_content" @click="adsObj.popupShow = true">
                                <div v-if="adsObj.province.name" class="form_item_ads_content_name">
                                    {{ adsObj.province.name }}
                                    {{ adsObj.city.name }}
                                    {{ adsObj.area.name }}
                                    {{ adsObj.town.name }}
                                </div>
                                <div class="form_item_ads_pla" v-else>请选择意向代理区域</div>
                            </div>
                        </div>
                        <el-button
                            style="margin: auto;margin-top: 40px;width: 280px;background-color: #d10000;border: none;color: #fff;font-size: 16px;"
                            @click="addJoinTownlet()">提交
                        </el-button>
                    </div>
                </div>
                <div v-if="!messageAddShow" :class="isShow?'tl_left_message_icon_box':'left_message_icon_box'"
                     @click="messageAddShow = true">
                    <img :class="isShow?'tl_message_icon':'message_icon'" src="../img/icons/message_icon.png" alt="">
                </div>
            </div>
        </div>

        <div class="jump" :class="isShow?'suspension_icon_tel':'suspension_icon'">
            <!--            <div class="click_suspension_icon" @click="clickChat()"></div>-->
            <img :class="isShow?'tl_suspension_icon_img':'suspension_icon_img'" src="../img/suspension_icon.png"
                 @click="clickChat()" alt="">
        </div>
        <div class="go-top-box" v-if="goTopShow">
            <i class="el-icon-arrow-up" @click="scrollToTop"></i>
        </div>

        <el-dialog title="提示" :visible.sync="adsObj.popupShow" :width="isShow?'80%':'30%'">
            <div class="ads_popup">
                <div class="ads_name_box">
                    <div class="popupShow_close">
                        <div></div>
                        <!-- <u-icon name="close" size="20" @click="adsObj.popupShow=false"></u-icon> -->
                    </div>
                    <div class="ads_item_name" @click="clickReSelect('province')" v-if="adsObj.province.name">
                        {{ adsObj.province.name }}
                        <img class="ads_item_right_icon" src="../img/icons/reight_icon.png"/>
                    </div>
                    <div class="ads_item_name color_ccc" @click="clickReSelect('province')" v-else>
                        请选择省
                        <img class="ads_item_right_icon" src="../img/icons/reight_icon.png"/>
                    </div>
                    <div v-if="adsObj.city.name" @click="clickReSelect('city')" class="ads_item_name">
                        {{ adsObj.city.name }}
                        <img class="ads_item_right_icon" src="../img/icons/reight_icon.png"/>
                    </div>
                    <div class="ads_item_name color_ccc" @click="clickReSelect('city')"
                         v-if="adsObj.province.name&&!adsObj.city.name&&!adsObj.area.name">
                        请选择市
                        <img class="ads_item_right_icon" src="../img/icons/reight_icon.png"/>
                    </div>
                    <div class="ads_item_name" @click="clickReSelect('area')" v-if="adsObj.area.name">
                        {{ adsObj.area.name }}
                        <img class="ads_item_right_icon" src="../img/icons/reight_icon.png"/>
                    </div>
                    <div class="ads_item_name color_ccc" @click="clickReSelect('area')"
                         v-if="adsObj.province.name&&adsObj.city.name&&!adsObj.area.name">
                        请选择县/区
                        <img class="ads_item_right_icon" src="../img/icons/reight_icon.png"/>
                    </div>
                    <div class="ads_item_name" @click="clickReSelect('town')" v-if="adsObj.town.name">
                        {{ adsObj.town.name }}
                        <img class="ads_item_right_icon" src="../img/icons/reight_icon.png"/>
                    </div>
                    <div class="ads_item_name color_ccc" @click="clickReSelect('town')"
                         v-if="adsObj.area.name&&!adsObj.town.name">
                        请选择乡/镇
                        <img class="ads_item_right_icon" src="../img/icons/reight_icon.png"/>
                    </div>
                </div>
                <div class="ads_item_box">
                    <div class="ads_list_item_name" @click="upDateAds(item)" v-for="item in adsObj.showAddressList"
                         :key="item.id">
                        {{ item.name }}
                    </div>
                </div>
            </div>

            <span slot="footer" class="dialog-footer">
				<el-button @click="adsObj.popupShow = false">取 消</el-button>
				<el-button type="primary" @click="adsObj.popupShow = false">确 定</el-button>
			</span>
        </el-dialog>
    </div>
</template>

<script>
import * as $api from '../utlis/api.js'

export default {
    mounted() {
        window.addEventListener('scroll', this.marignTop);
        this.getProvince()

        let device = navigator.userAgent;
        // 判断是手机还是电脑
        this.isShow = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(device);
    },
    // watch: {
    //     $route(to) {
    //         if (to.path === '/') {
    //             this.$router.go(0)
    //             console.log("to.path = ", to.path)
    //         }
    //     }
    // },
    data() {
        return {
            isShow: false,
            goTopShow: false,
            user: {
                name: "",
                mobile: "",
                townAddress: "",
                townName: "",
                source: "PC"
            },
            messageAddShow: false,
            goMessageAddIndex: 0,
            adsObj: {
                popupShow: false,
                province: {
                    name: "",
                    id: "",
                    adsId: ""
                },
                city: {
                    name: "",
                    id: "",
                    adsId: ""
                },
                area: {
                    name: "",
                    id: "",
                    adsId: ""
                },
                town: {
                    name: "",
                    id: "",
                    adsId: ""
                },
                showAddressList: [],
                provinceType: 0, //如果是直辖市就为1
            }
        }
    },
    beforeDestroy() {
        window.removeEventListener('scroll', this.marignTop);
    },
    methods: {
        clickChat() {
            window.open('https://work.weixin.qq.com/kfid/kfcbc512cc99ab229cc')
            // this.$router.push({
            //     path: "/WeixinChat"
            // })
        },
        marignTop() {
            if (window.scrollY > 300) {
                this.goTopShow = true
            } else {
                this.goTopShow = false
            }
            // console.log(window.scrollY)
            if (this.goMessageAddIndex < 1) {
                if (window.scrollY > 800) {
                    this.messageAddShow = true
                    this.goMessageAddIndex = 1
                }
            }
            if (window.scrollY == 0) {
                this.messageAddShow = false
            }
        },
        scrollToTop() {
            const body = document.body;
            const docEl = document.documentElement;
            let scrollTop = window.pageYOffset || docEl.scrollTop || body.scrollTop;

            let scrollInterval = setInterval(() => {
                if (scrollTop > 0) {
                    scrollTop -= 50;
                    window.scrollTo(0, scrollTop);
                } else {
                    clearInterval(scrollInterval);
                }
            }, 10);
        },
        // 查询全国省名称
        getProvince() {
            this.adsObj.showAddressList = []
            $api.getProvince({}).then(res => {
                // console.log("省地址",res)
                this.adsObj.showAddressList = res.data
            })
        },
        // 获取市信息
        getCity(data) {
            this.adsObj.showAddressList = []
            $api.getCity(data).then(res => {
                if (res.data.length < 1) {
                    this.adsObj.provinceType = 1
                    this.getArea({province: data.province, city: 1})
                } else {
                    this.adsObj.provinceType = 0
                }
                this.adsObj.showAddressList = res.data
            })
        },
        // 获取县信息
        getArea(data) {
            this.adsObj.showAddressList = []
            $api.getArea(data).then(res => {
                this.adsObj.showAddressList = res.data
            })
        },
        // 获取镇信息
        getTown(data) {
            this.adsObj.showAddressList = []
            $api.getTown(data).then(res => {
                this.adsObj.showAddressList = res.data
            })
        },

        upDateAds(item) {
            // console.log(item)
            if (item.city == 0) {
                this.adsObj.province.name = item.name
                this.adsObj.province.id = item.province
                this.adsObj.province.adsId = item.id

                this.adsObj.city.name = ""
                this.adsObj.city.id = ""
                this.adsObj.city.adsId = ""
                this.adsObj.area.name = ""
                this.adsObj.area.id = ""
                this.adsObj.area.adsId = ""
                this.adsObj.town.name = ""
                this.adsObj.town.id = ""
                this.adsObj.town.adsId = ""
                this.getCity({province: item.province})

            } else if (item.area == 0) {
                this.adsObj.city.name = item.name
                this.adsObj.city.id = item.city
                this.adsObj.city.adsId = item.id

                this.adsObj.area.name = ""
                this.adsObj.area.id = ""
                this.adsObj.area.adsId = ""
                this.adsObj.town.name = ""
                this.adsObj.town.id = ""
                this.adsObj.town.adsId = ""


                this.getArea({province: item.province, city: item.city})
            } else if (item.town == 0) {
                this.adsObj.area.name = item.name
                this.adsObj.area.id = item.area
                this.adsObj.area.adsId = item.id
                this.adsObj.town.name = ""
                this.adsObj.town.id = ""
                this.adsObj.town.adsId = ""
                this.getTown({province: item.province, city: item.city, area: item.area})
            } else if (item.town) {
                this.adsObj.town.name = item.name
                this.adsObj.town.id = item.town
                this.adsObj.town.adsId = item.id
                this.adsObj.popupShow = false
            }
        },

        clickReSelect(type) {
            if (type == "province") {
                this.getProvince()
            } else if (type == "city") {
                this.getCity({province: this.adsObj.province.id})
            } else if (type == "area") {
                if (this.adsObj.provinceType == 1) {
                    this.getArea({province: this.adsObj.province.id, city: 1})
                } else {
                    this.getArea({province: this.adsObj.province.id, city: this.adsObj.city.id})
                }
            } else if (type == "town") {
                if (this.adsObj.provinceType == 1) {
                    this.getTown({province: this.adsObj.province.id, city: 1, area: this.adsObj.area.id})
                } else {
                    this.getTown({
                        province: this.adsObj.province.id,
                        city: this.adsObj.city.id,
                        area: this.adsObj.area.id
                    })
                }
            }
        },


        addJoinTownlet() {
            this.user.townAddress = ""
            this.user.townName = ""
            for (let k in this.adsObj) {
                if (this.adsObj[k].name) {
                    this.user.townAddress += this.adsObj[k].name
                }
                if (this.adsObj[k].adsId) {
                    this.user.townName += this.adsObj[k].adsId + ','
                }
            }
            this.user.townName = this.user.name.slice(0, -1)

            if (this.user.name.trim() == '') {
                return this.$notify({
                    message: '请填写您的姓名',
                    type: 'error'
                });
            } else if (this.user.mobile.trim() == '') {
                return this.$notify({
                    message: '请填写您的手机号',
                    type: 'error'
                });
            } else if (this.user.townAddress.trim() == '') {
                return this.$notify({
                    message: '请选择代理区域',
                    type: 'error'
                });
            } else if (!/^\d{11}$/.test(this.user.mobile)) {
                return this.$notify({
                    message: '请填写正确的的手机号',
                    type: 'error'
                });
            }

            $api.addJoin(this.user).then(res => {
                if (res.code == 0) {
                    this.$notify({
                        title: '提交成功',
                        message: res.msg || "我们的工作人员将在48小时内与您联系",
                        type: 'success'
                    });
                    this.user = {
                        name: "",
                        mobile: "",
                        townAddress: "",
                        townName: ""
                    }
                    this.adsObj.province.id = ""
                    this.adsObj.province.adsId = ""

                    this.adsObj.city.name = ""
                    this.adsObj.city.id = ""
                    this.adsObj.city.adsId = ""
                    this.adsObj.area.name = ""
                    this.adsObj.area.id = ""
                    this.adsObj.area.adsId = ""
                    this.adsObj.town.name = ""
                    this.adsObj.town.id = ""
                    this.adsObj.town.adsId = ""
                } else {
                    this.$notify({
                        message: res.msg,
                        type: 'error'
                    });
                }
                console.log(res)
            })
        }
    }
}
</script>

<style scoped lang="less">

@import url("../css/know.less");

.go-top-box {
    position: fixed;
    right: 30px;
    bottom: 60px;
    z-index: 9999;

    i {
        font-size: 25px;
        height: 30px;
        width: 30px;
        border-radius: 50%;
        border: 1px solid #ccc;
        text-align: center;
        line-height: 30px;
        color: #343434;
        background-color: #f3f3f3;
    }

    i:hover {
        background-color: #fff;
        transition: .3s;
    }
}

.suspension_icon {
    position: fixed;
    right: 30px;
    bottom: 120px;
    z-index: 9999;
    animation-name: jump;
    animation-duration: 1s;
    animation-delay: 1s;
    animation-timing-function: ease-in-out;
    animation-direction: alternate-reverse;
    animation-play-state: running;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
}

.suspension_icon_tel {
    position: fixed;
    right: 15px;
    bottom: 120px;
    z-index: 9999;
    animation-name: jump;
    animation-duration: 1s;
    animation-delay: 1s;
    animation-timing-function: ease-in-out;
    animation-direction: alternate-reverse;
    animation-play-state: running;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
}

.suspension_icon_img {
    width: 215px;
    height: 160px;
    object-fit: cover;
}

.tl_suspension_icon_img {
    width: 107px;
    height: 80px;
    object-fit: contain;
}

.click_suspension_icon {
    position: absolute;
    width: 50px;
    height: 30px;
    right: 15px;
    bottom: 8px;
    // background-color: #d10000;
    z-index: 9;
}

.suspension_icon:hover {
    /* 停止动画 */
    animation: none;
}

@keyframes jump {
    0% {
        transform: translateY(0);
    }

    50% {
        transform: translateY(-10px);
    }

    100% {
        transform: translateY(0);
    }
}


.left_message {
    position: fixed;
    left: 30px;
    bottom: 80px;
    z-index: 3;
}

.left_message_tel {
    position: fixed;
    left: 15px;
    bottom: 130px;
    z-index: 3;
}

.left_message_icon_box {
    position: relative;
    background-color: rgba(255, 255, 255, 0.8) !important;
    width: 35px;
    height: 35px;
    padding: 10px;
    border-radius: 50%;
    border: 1px solid #f00;

    animation-name: show;
    animation-duration: 2s;
    animation-delay: 2s;
    animation-timing-function: ease-in-out;
    animation-direction: alternate-reverse;
    animation-play-state: running;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
}

@keyframes show {
    0% {
        opacity: 1;
    }

    50% {
        opacity: .5;
    }

    100% {
        opacity: 1;
    }
}

.left_message_icon_box:hover {
    animation: none;
}


.message_icon {
    width: 35px;
    height: 35px;
}

.tl_left_message_icon_box {
    position: relative;
    background-color: rgba(255, 255, 255, 0.8) !important;
    width: 25px;
    height: 25px;
    padding: 10px;
    border-radius: 50%;
    border: 1px solid #f00;

    animation-name: show;
    animation-duration: 2s;
    animation-delay: 2s;
    animation-timing-function: ease-in-out;
    animation-direction: alternate-reverse;
    animation-play-state: running;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
}

.tl_message_icon {
    width: 25px;
    height: 25px;
}


.message_add_join {
    width: 300px;
    height: 500px;
    background-color: #f6f6f6;
    position: absolute;
    bottom: 0;
    left: 0;
    background-color: rgba(255, 255, 255, 0.7);
    border-radius: 10px;
    overflow: hidden;
    transform: translatex(-120%);
    animation-name: left;
    animation-duration: .2s;
    animation-delay: .2s;
    animation-timing-function: ease-in-out;
    animation-direction: alternate-reverse;
    animation-play-state: running;
    animation-fill-mode: forwards;
}

@keyframes left {
    0% {
        transform: translatex(0);
    }

    100% {
        transform: translatex(-100%);
    }
}

.box_shadow {
    box-shadow: 0 0 5px 3px #e3e3e3;
}

.message_add_join_back {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.6);
    z-index: -1;
}

.message_add_join_bg_img {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -2;
    object-fit: cover;
}

.message_add_join_top {
    width: 100%;
    height: 40px;
    background-color: #d10000;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #fff;
    font-weight: bold;
    font-size: 16px;
    box-sizing: border-box;
    padding: 0 10px;
}

.message_add_join_show_box {
    height: 100%;
    display: flex;
    align-items: center;
}

.message_add_join_show {
    width: 15px;
    height: 3px;
    background-color: #fff;
}

.message_add_form_title {
    padding: 15px 10px;
    border-bottom: 1px solid #ccc;
}

.message_add_form_title_name {
    font-size: 18px;
    font-weight: bold;
}

.message_add_form_title_name_too {
    font-size: 14px;
    color: #7c7c7c;
}

.message_add_form {
    box-sizing: border-box;
    padding: 10px 10px;
    width: 100%;
    margin: 0 auto;
}
</style>