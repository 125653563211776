<template>
	<!-- 底部 -->
	<div>
		<div class="foot-box" v-if="!isShow">
			<div class="content">
				<div class="content_top">
					<div class="logo_adn_menu_box">
						<img class="logo" src="../img/logo.png" alt="">
						<div class="menu_box">
							<el-popover placement="top" trigger="hover">
								<div class="ewm_popover">
									<div class="ewm_popover_img_box">
										<img class="ewm_popover_img" src="../img/sjdewm.png" alt="">
										<div class="ewm_popover_img_title">商家端</div>
									</div>
									<div class="ewm_popover_img_box">
										<img class="ewm_popover_img mar_left_10" src="../img/qsdewm.png" alt="">
										<div class="ewm_popover_img_title">骑手端</div>
									</div>
								</div>
								<img slot="reference" class="menu_icon" src="../img/icons/phone_icon.png" alt="">
							</el-popover>
							<el-popover placement="top" trigger="hover">
								<div class="ewm_popover">
									<div class="ewm_popover_img_box">
										<img class="ewm_popover_img" src="../img/wxgzhewm.jpg" alt="">
										<div class="ewm_popover_img_title">微信公众号</div>
									</div>
								</div>
								<img slot="reference" class="menu_icon" src="../img/icons/wx_icon.png" alt="">
							</el-popover>
							<el-popover placement="top" trigger="hover">
								<div class="ewm_popover">
									<div class="ewm_popover_img_box">
										<img class="ewm_popover_img" src="../img/dyewm.png" alt="">
										<div class="ewm_popover_img_title">抖音小程序</div>
									</div>
								</div>
								<img slot="reference" class="menu_icon" src="../img/icons/Tiktok_icon.png" alt="">
							</el-popover>
						</div>
					</div>
					<div class="foot">
						<div class="introduce">
							<p>企业名称： 祥源（河南）科技集团有限公司</p>
							<p>网站：www.waimaishi.cn</p>
							<p>微信公众号：外卖狮官方服务号</p>
							<p>抖音号：外卖狮</p>
						</div>

						<div class="foot_center">
							<div class="foot_center_title_box">
								<div class="foot_center_title">
									加入我们
								</div>
								<div class="foot_center_title_url">wms@waimaishi.cn</div>
							</div>
							<div class="foot_center_kf">
								客服 400-666-9951
							</div>

						</div>

						<div class="ewm_box">
							<div class="ewm_item">
								<img class="ewm_img" src="../img/yhdewm.png" alt="">
								<img class="ewm_img" src="../img/wxxiaochengxu.png" alt="">
							</div>
							<p class="ewm_title">更多商家，更多优惠</p>
						</div>
					</div>
				</div>

				<div class="btmTxt">
					<a target="_blank" href="https://beian.miit.gov.cn/">Copyright © 2016-2025
						祥源（河南）科技集团有限公司 版权所有
						保留一切权利
						备案号:豫ICP备2022011648号</a>
					<a target="_blank" href="https://beian.miit.gov.cn/#/Integrated/index">
						经营许可证编号: 豫B2-20231104</a>
				</div>
			</div>
		</div>
		<div class="tl_foot_box" v-else>
			<div class="tl_foot_top">
				<img class="tl_foot_logo" src="../img/logo.png" alt="">
				<div class="tl_foot_top_icon_box">
					<el-popover placement="top" trigger="hover">
						<div class="ewm_popover">
							<div class="ewm_popover_img_box">
								<img class="ewm_popover_img" src="../img/sjdewm.png" alt="">
								<div class="ewm_popover_img_title">商家端</div>
							</div>
							<div class="ewm_popover_img_box">
								<img class="ewm_popover_img mar_left_10" src="../img/qsdewm.png" alt="">
								<div class="ewm_popover_img_title">骑手端</div>
							</div>
						</div>
						<img slot="reference" class="tl_menu_icon" src="../img/icons/phone_icon.png" alt="">
					</el-popover>
					<el-popover placement="top" trigger="hover">
						<div class="ewm_popover">
							<div class="ewm_popover_img_box">
								<img class="ewm_popover_img" src="../img/wxgzhewm.jpg" alt="">
								<div class="ewm_popover_img_title">微信公众号</div>
							</div>
						</div>
						<img slot="reference" class="tl_menu_icon" src="../img/icons/wx_icon.png" alt="">
					</el-popover>
					<el-popover placement="top" trigger="hover">
						<div class="ewm_popover">
							<div class="ewm_popover_img_box">
								<img class="ewm_popover_img" src="../img/dyewm.png" alt="">
								<div class="ewm_popover_img_title">抖音小程序</div>
							</div>
						</div>
						<img slot="reference" class="tl_menu_icon tl_dy_icon" src="../img/icons/Tiktok_icon.png" alt="">
					</el-popover>
				</div>

				<!-- 企业信息 -->
				<div class="tl_foot_content">
					<div class="tl_foot_content_item" v-for="item in tlContentList" :key="item.title">
						<div class="tl_foot_content_item_title">
							{{item.title}}
						</div>
						<div class="tl_foot_content_item_content">
							{{item.content}}
						</div>
					</div>
				</div>

				<div class="tl_ewm_content">
					<div class="tl_ewm_box">
						<img class="tl_ewm_img" src="../img/yhdewm.png" alt="">
						<img class="tl_ewm_img" src="../img/wxxiaochengxu.png" alt="">
					</div>
					<div class="tl_ewm_title">更多商家，更多优惠</div>
				</div>

				<div class="tl_permit">
					<a target="_blank" href="https://beian.miit.gov.cn/">Copyright © 2016-2025 祥源（河南）科技集团有限公司版权所有 保留一切权利
						备案号:豫ICP备2022011648号</a>
					<a target="_blank" href="https://beian.miit.gov.cn/#/Integrated/index">经营许可证编号: 豫B2-20231104</a>
				</div>
			</div>
		</div>
	</div>

</template>

<script>
	export default {
		data() {
			return {
				isShow: false,
				// 手机端页脚内容
				tlContentList: [{
						title: "企业名称",
						content: "祥源（河南）科技集团有限公司"
					},
					{
						title: "官方网站",
						content: "www.waimaishi.cn"
					},
					// {
					// 	title: "微信公众号",
					// 	content: "外卖狮官方服务号"
					// },
					// {
					// 	title: "抖音号",
					// 	content: "外卖狮"
					// },
					{
						title: "加入我们",
						content: "wms@waimaishi.cn"
					},
					{
						title: "客服热线",
						content: "400-666-9951"
					},
				]
			}
		},
		mounted() {
			let device = navigator.userAgent;
			// 判断是手机还是电脑
			this.isShow = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(device);
		},
	}
</script>

<style scoped lang="less">
	@import url("../css/navFootToo.less");
</style>