import request from '../utlis/request.js'

export function addJoin(data) {
	return request.post('system/ow-merchants-join/createGW', data)
}

export function save(data) {
	return request.get('/save', data)
}

// 查询全国省名称
export function getProvince(data){
	return request.post('/system/city/getProvince', data)
}

// 招商加盟
// 查询市名称
export function getCity(data){
	return request.post('system/city/getCity', data)
}

// 查询县名称
export function getArea(data){
	return request.post('system/city/getArea', data)
}

// 查询镇名称
export function getTown(data){
	return request.post('system/city/getTown', data)
}




// 加入我们

// 获取可加入小镇列表
export function getTownList(data) {
	return request.post('system/ow-merchants-join/getTownList', data)
}
// 申请骑手
export function createRiderGW(data) {
	return request.post('system/ow-merchants-join/createRiderGW', data)
}